import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import VueSweetalert2 from 'vue-sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import store from '@/store'

export default function useStatutsList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  const userData = ref(null)
  const isMaxedOut = ref(true)
  userData.value = JSON.parse(localStorage.getItem('userData'))

  // Table Handlers
  const tableColumns = [
    {
      key: 'isDefault', sortable: true, label: '', thClass: 'w-125',
    },
    { key: 'Titre', sortable: true },
    {
      key: 'estResolu', sortable: true, label: 'Résolu ?', thClass: 'w-125',
    },
    {
      key: 'publie', label: 'Publié ?', sortable: true, thClass: 'w-125',
    },
    { key: 'actions', thClass: 'w-125' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const isLoading = ref(true)

  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchUsers = (ctx, callback) => {
    isLoading.value = true
    store
      .dispatch('app-statut/fetchStatuts', {
        q: searchQuery.value,
        limit: perPage.value,
        skip: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { data, pagination } = response.data

        callback(data)
        totalUsers.value = pagination.totalCount
        isLoading.value = false
        isMaxedOut.value = userData.pack === 1 && pagination.totalCount >= 6
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur pendant le chargement de la liste des utilisateurs',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role && role.includes('ROLE_SUPER_ADMIN')) return 'primary'
    if (role && role.includes('ROLE_ADMIN')) return 'danger'
    if (role && role.includes('ROLE_MAINTENANCE')) return 'warning'
    if (role && role.includes('ROLE_USER')) return 'info'
    return 'info'
  }

  const resolveUserRoleIcon = role => {
    if (role && role.includes('ROLE_ADMIN')) return 'SettingsIcon'
    if (role && role.includes('ROLE_MAINTENANCE')) return 'warning'
    if (role && role.includes('ROLE_USER')) return 'UserIcon'
    return 'UserIcon'
  }

  const resolveStatutText = role => {
    if (role && role.includes('ROLE_SUPER_ADMIN')) return 'Super Administrateur'
    if (role && role.includes('ROLE_ADMIN')) return 'Administrateur'
    if (role && role.includes('ROLE_MAINTENANCE')) return 'Maintenance'
    if (role && role.includes('ROLE_USER')) return 'Utilisateur'
    return 'danger'
  }

  const resolveUserStatusVariant = status => {
    if (status === true) return 'success'
    if (status === false) return 'secondary'
    return 'success'
  }
  const resetPassword = id => {
    Vue.swal({
      title: 'Nouveau mot de passe ?',
      text: 'Envoyer un email de ré-initialisation du mot de passe ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui !',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        store
          .dispatch('app-user/resetPasswordUser', id)
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Un email a été envoyé à l utilisateur',
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
          })
          .catch(err => {
            toast({
              component: ToastificationContent,
              props: {
                title: err.response.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }
  const changeStatus = (status, id) => {
    store
      .dispatch('app-user/changeStatusUser', { status, id })
      .then(response => {
        if (response.data.message !== 'ok') {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Erreur pendant la modification de l utilisateur',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } else {
          refetchData()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Utilisateur modifié avec succès',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erreur pendant la modification de l utilisateur',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchUsers,
    isLoading,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveStatutText,
    refetchData,
    changeStatus,
    resetPassword,

    userData,
    isMaxedOut,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
