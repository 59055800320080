import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchStatuts(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statut/all-unfiltered', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStatut(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/statut/view/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addStatut(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statut/add', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editStatut(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statut/edit', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatutStatut(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/statut/update-status', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
